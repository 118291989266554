import React from 'react'

import { Col, Container, Row } from 'react-bootstrap'
import { fadeIn } from '../../Common/GlobalAnimations';
import { motion } from 'framer-motion';
import ContactForm from './ContactForm';


const Subscribe = () => {

    return (
        <motion.section className="contact-from-bg about-me-subscribe relative pb-[130px] lg:pb-[90px] md:pb-[75px] sm:pb-[50px]" {...fadeIn}>
            <Container>
                <div className='subscribe-looq-icon'>
                    <img src='/assets/images/logos/icon.png' alt="icon" />
                </div>
                <Row className='flex items-center row'>
                    <Col className="text-center" md={12} lg={6} sm={12} xs={12}>
                        <div className="justify-center flex w-full">
                            <h5 className="get-in-touch font-bold -tracking-[0.5px] color-000000 font-size-46 font-Roboto-Condensed inline-block mb-[60px] sm:mb-[30px]">
                                Find out more <br></br> Get in touch</h5>
                        </div>
                    </Col>
                    <Col md={12} lg={6} sm={12} xs={12}>
                        <div className="justify-center flex w-full">
                            <ContactForm></ContactForm>
                        </div>
                    </Col>
                </Row>
                {/* <Row className="justify-center">
                    <Col md={8} className="flex justify-center">
                        <Formik
                            initialValues={{ email: '' }}
                            validationSchema={Yup.object().shape({ email: Yup.string().email("Invalid email.").required("Field is required."), })}
                            onSubmit={async (values, actions) => {
                                actions.setSubmitting(true)
                                const response = await sendEmail(values)
                                response.status === "success" && resetForm(actions)
                            }}
                        >
                            {({ isSubmitting, status }) => (
                                <div className="relative subscribe-style-02 w-4/5 md:w-full">
                                    <Form className="relative">
                                        <Input showErrorMsg={false} type="email" name="email" className="border-[1px] medium-input border-solid border-transparent !pr-[175px] xs:!pr-[32px]" placeholder="Enter your email address" />
                                        <button type="submit" className={`text-xs py-[12px] px-[28px] ${isSubmitting ? " loading" : ""}`}><MailOutlineIcon></MailOutlineIcon> &nbsp; subscribe</button>
                                    </Form>
                                    <AnimatePresence>
                                        {status && <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className="absolute top-[115%] left-0 w-full">
                                            <MessageBox className="py-[5px] rounded-[100px] text-center z-[1]" theme="message-box01" variant="success" message="Your message has been sent successfully subscribed to our email list!" />
                                        </motion.div>}
                                    </AnimatePresence>
                                </div>
                            )}
                        </Formik>
                    </Col>
                </Row> */}
            </Container>
        </motion.section>
    )

}
export default Subscribe;