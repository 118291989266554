
import React, { useRef, useLayoutEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

const Product = () => {
    const videoRef = useRef();
    const productRef = useRef();
    useLayoutEffect(() => {
        function updateSize() {
            if (productRef.current && videoRef.current) {
                var height = productRef.current.getBoundingClientRect().height;
                videoRef.current.style.height = height + 'px';
            }

        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);

    }, [])
    return (
        <section className="py-[100px] lg:pb-[90px] md:pb-[75px] sm:pb-[50px] overflow-hidden startup-processstep rotate-camera-sec">
            <Container>
                <Row className="items-center justify-center">

                    <div className="col-lg-6 md:mt-[50px]">
                        <div className="relative md:mr-0 flex justify-center" >
                            <video ref={videoRef} muted loop={true} autoPlay={true} playsInline={true} src='/assets/img/AnimationTest8.mp4' type="video/mp4"></video>
                            {/* <img src="/assets/img/device.png" width={496} height={575} className="overflow-hidden" alt="" /> */}
                        </div>
                    </div>
                    <Col xl={6} lg={6} md={10} ref={productRef}>
                        <h1 className="font-Roboto-Condensed font-medium color-000000 font-size-80 mb-0 sm:mb-[10px] line-height flex items-center">The <img className='ml-3 looq-icon' src="/assets/images/logos/icon.png" alt="icon" /></h1>
                        <ul className="camera-technology-list">
                            <li>
                                Proprietary 3D camera technology
                            </li>
                            <li>
                                User-Friendly Design
                            </li>
                            <li>
                                Onboard smart capture
                            </li>
                            <li>
                                Survey-grade GPS
                            </li>
                        </ul>
                        {/* <div class="row">
                             <div class="xs:mb-[5px] col-md-2">
                                <img src="/assets/img/camera.png" alt="camera" />
                            </div> 
                            <div class="mb-[20px] text-left sm:text-center font-medium col-md-10 font-Roboto-Condensed color-000000 font-size-32 line-height">
                                Proprietary 3D camera technology
                            </div>
                        </div>
                        <div class="row">
                             <div class="xs:mb-[5px] col-md-2">
                                <img src="/assets/img/clock.png" alt="clock" />
                            </div> 
                            <div class="mb-[20px] text-left sm:text-center font-medium col-md-10 font-Roboto-Condensed color-000000 font-size-32 line-height">
                                User-Friendly Design
                            </div>
                        </div>
                        <div class="row">
                            <div class="xs:mb-[5px] col-md-2">
                                <img src="/assets/img/brainstorming.png" alt="brainstorming" />
                            </div> 
                            <div class="mb-[20px] text-left sm:text-center font-medium col-md-10 font-Roboto-Condensed color-000000 font-size-32 line-height">
                                Onboard smart capture
                            </div>
                        </div>
                        <div class="row">
                            <div class="xs:mb-[5px] col-md-2">
                                <img src="/assets/img/map.png" alt="map" />
                            </div> 
                            <div class="text-left sm:text-center font-medium col-md-10 font-Roboto-Condensed color-000000 font-size-32 line-height">
                                Survey-grade GPS
                            </div>
                        </div> */}
                    </Col>
                </Row>
            </Container>
        </section>
    )

}
export default Product;