import React from 'react';
import { Container } from 'react-bootstrap';
import { fadeIn } from '../../Common/GlobalAnimations';
import { motion } from 'framer-motion';
import { Row, Col } from "react-bootstrap";



export const Benefits = () => {
    return (

        <motion.section className="py-[100px] lg:py-[100px] md:py-[95px] sm:py-[80px] xs:py-[50px] why-looq-bg" {...fadeIn}>
            <Container>
                <Row className="justify-center">
                    <div class="mb-3 text-center col-lg-12">
                        <h2 class="font-size-46 font-Roboto-Condensed font-bold color-E0604C">Why Looq?</h2></div>
                </Row>
                {/* <Row>
                    <Col lg={5} md={6} className="mt-5 md:mt-0 sm:mb-[30px]">
                        <h2 className="heading-5 font-medium font-serif text-darkgray w-[90%] mb-[30px]  lg:w-full sm:mb-[15px]">We design <span className="font-semibold">brand, digital experience</span> that engage the right customers</h2>
                        <Link to="/page/what-we-offer/" className="mt-[15px] text-[#969896] text-xmd font-serif hover:text-[#969896]">More services
                            <i className="line-icon-Arrow-OutRight text-[40px] inline-block ml-[15px] align-middle"></i>
                        </Link>
                    </Col>
                    <Col lg={{ offset: 1 }} md={6} className="mb-[30px] mt-5 md:mt-0">
                        <ProgressBar theme="progressbar-style-01 marketing-agency-progressbar" trailColor="transparent" data={ProgressBarData} height="8px" color="#f4d956" animation={fadeIn} />
                    </Col>
                </Row> */}
                <Row>
                    <Col lg={4} md={6} className="mt-5 md:mt-0 sm:mb-[30px]">
                        <div class="flex align-items-start">
                            <img class="mr-[10px] xs:w-[40px] xs:mr-[10px]" src="/assets/img/Icons-01.svg" alt="camera" />
                            <p className='font-Roboto-Condensed font-size-28 font-bold color-000000 line-height'>Reduce project timelines and costs</p>
                        </div>
                    </Col>
                    <Col lg={4} md={6} className="mt-5 md:mt-0 sm:mb-[30px]">
                        <div class="flex align-items-start">
                            <img class="mr-[10px] xs:w-[40px] xs:mr-[10px]" src="/assets/img/Icons-02.svg" alt="camera" />
                            <p className='font-Roboto-Condensed font-size-28 font-bold color-000000 line-height'>Quickly scale operations</p>
                        </div>
                    </Col>
                    <Col lg={4} md={6} className="mt-5 md:mt-0 sm:mb-[30px]">
                        <div class="flex align-items-start">
                            <img class="mr-[10px] xs:w-[40px] xs:mr-[10px]" src="/assets/img/Icons-03.svg" alt="camera" />
                            <p className='font-Roboto-Condensed font-size-28 font-bold color-000000 line-height'>Improve decision making with data-driven insights</p>
                        </div>
                    </Col>

                    <Col lg={4} md={6} className="mt-5 md:mt-0 sm:mb-[30px]">
                        <div class="flex align-items-start">
                            <img class="mr-[10px] xs:w-[40px] xs:mr-[10px]" src="/assets/img/Icons-04.svg" alt="camera" />
                            <p className='font-Roboto-Condensed font-size-28 font-bold color-000000 line-height'>Mitigate risk and decrease field incidents</p>
                        </div>
                    </Col>
                    <Col lg={4} md={6} className="mt-5 md:mt-0 sm:mb-[30px]">
                        <div class="flex align-items-start">
                            <img class="mr-[10px] xs:w-[40px] xs:mr-[10px]" src="/assets/img/Icons-05.svg" alt="camera" />
                            <p className='font-Roboto-Condensed font-size-28 font-bold color-000000 line-height'>End-to-end field to office workflow</p>
                        </div>
                    </Col>
                    <Col lg={4} md={6} className="mt-5 md:mt-0 sm:mb-[30px]">
                        <div class="flex align-items-start">
                            <img class="mr-[10px] xs:w-[40px] xs:mr-[10px]" src="/assets/img/Icons-06.svg" alt="camera" />
                            <p className='font-Roboto-Condensed font-size-28 font-bold color-000000 line-height'>Easily collaborate with clients and contractors</p>
                        </div>
                    </Col>
                </Row>
                {/* <Counter theme="counter-style-04 marketing-agency-counter" grid="row-cols-1 row-cols-md-4 row-cols-sm-2 gap-y-10 mt-[118px] sm:mt-[60px]" className="text-black" duration={2} data={CounterData04} /> */}
            </Container>
        </motion.section>
    )
}