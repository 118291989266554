import { useEffect } from "react";

const InvestorDeckPublicRedirect = () => {
    useEffect(() => {
        window.location.href = 'https://docsend.com/view/fi5pyi5m3nwmfrpd';
    }, []);
    return (<></>);
}

export default InvestorDeckPublicRedirect;
