import { Col, Row, Container } from 'react-bootstrap';
import { motion } from 'framer-motion';
import { fadeIn } from '../../Common/GlobalAnimations';
import TestimonialsCarouselData from './TestimonialsCarouselData';

const TestimonialsCarouselData4 = [
    {
        firstname: "Blake",
        lastname: "Darling",
        designation: "Aquawolf",
        title: "Just love their design for all stunning details!",
        content: "“The Looq AI-enabled technology platform has become an indispensable engineering tool in our fielding tool kit. Their unique hardware and groundbreaking back-end processing has enabled our design teams to capture highly detailed and accurate field conditions in the absence of survey data. The platform has enabled us to fill holes in our survey base-maps quickly and reliably, with rich data that keeps designs moving forward. Looq has been a key component in helping Aquawolf maintain its reputation for agility, high-quality engineering, and design in the utility industry.”",
        img: "assets/img/Testmonials-final-1.png"
    },
    {
        firstname: "Amanda Jones,",
        lastname: "PLS",
        designation: "Owner - Amanda Jones Surveying Inc.",
        title: "Every element is designed beautifully and perfect!",
        content: "“The use of this technology is a game-changer for us.It gives us the opportunity to capture a large amount of survey-grade data without drones or hours and hours of boots-on-the-ground labor.”",
        img: "assets/img/Testmonials-final-2.png"
    },
    {
        firstname: "Kevin",
        lastname: "Grover",
        designation: "McElhanney Ltd.",
        title: "Simple and easy to integrate and build the website!",
        content: "“I haven’t been this excited about a new 3D capture technology in a long time. The speed and quality of the survey data from Looq is paramount to other 3D technology on the market today. Looq is going to be disrupting the industry and we are excited to be an early partner of theirs at McElhanney in Canada.”",
        img: "assets/img/Testmonials-final-3.png"
    },
]

export const CustomerReview = () => {
    return (
        // <motion.section className="testimonials-sec bg-gradient-to-b from-[#fff] to-[#f0f2f0] pb-[130px] overflow-hidden lg:pb-[90px] md:pb-[75px] sm:pb-[50px]" {...fadeIn}>
        //     <div className='container-fluid px-5'>
        //         <Row className="justify-center text-center">
        //             <Col lg={12} className="mb-16 text-center">
        //                 <span className="mb-[5px] text-[#A4A4A4] block tracking-[1px] font-size-16">Testimonials</span>
        //                 <h2 className="font-size-46 font-Roboto-Condensed font-bold color-E0604C">Customer Reviews</h2>
        //             </Col>
        //         </Row>
        //         <Row className="justify-center px-5">
        //             <Col md={12} sm={8}>
        //                 <Testimonials className="marketing-agency-testimonials" grid="row-cols-1 row-cols-md-2 row-cols-lg-3 gap-y-10 justify-center" theme='testimonials-style-03' data={TestimonialsData03} animation={fadeIn} />
        //             </Col>
        //         </Row>
        //     </div>
        // </motion.section>
        <motion.section className="py-[100px] overflow-hidden lg:py-[90px] md:py-[75px] sm:py-[50px] testimonial-new-sec" {...fadeIn}>
            <Container>
                <Row className="justify-center">
                    <Col lg={12} className="text-center">
                        <h2 className="font-size-46 font-Roboto-Condensed font-bold color-E0604C">Testimonials</h2>
                    </Col>
                    <TestimonialsCarouselData
                        data={TestimonialsCarouselData4}
                        className="black-move"
                        carouselOption={{
                            slidesPerView: 1,
                            loop: true,
                            breakpoints: { 768: { slidesPerView: 2 } },
                            navigation: false,
                            //   autoplay: { delay: 3000, disableOnInteraction: false }
                        }}
                    />
                </Row>
            </Container>
        </motion.section>

    )
}