import React, { useRef } from 'react'

// Libraries
import { Container } from 'react-bootstrap';
import { motion, AnimatePresence } from 'framer-motion';
import { Formik, Form } from 'formik';

// Components
import { ContactFormStyle02Schema } from '../Functions/FormSchema';
import MessageBox from '../Functions/MessageBox';
import Buttons from "../Functions/Buttons";
import { resetForm } from '../Functions/Utilities';
import { Input, Select, TextArea } from '../Functions/Form';
import ReCAPTCHA from 'react-google-recaptcha';


const ContactForm = () => {
    const form1 = useRef(null)
    const recaptcha = useRef()

    const hubSpotPortalId = '23352734';
    const hubSpotFormId = 'fae3c51a-4b7e-41ab-ab36-c2bcd63579fd';

    const hubLocalSpotPortalId = '45278723';
    const hubLocalSpotFormId = '62ae773d-fe28-4eb7-be1f-211d3c68dacf';


    const submitDataToHubSpot = async (data, actions) => {
        var hubSpotUrl = '';
        if (window.location.origin.includes('localhost') || window.location.origin.includes('10.200.0.78')) {
            //hubSpotUrl = `https://forms.hubspot.com/uploads/form/v2/${hubSpotPortalId}/${hubSpotFormId}`;
            hubSpotUrl = `https://forms.hubspot.com/uploads/form/v2/${hubLocalSpotPortalId}/${hubLocalSpotFormId}`;
        } else {
            hubSpotUrl = `https://forms.hubspot.com/uploads/form/v2/${hubSpotPortalId}/${hubSpotFormId}`;
        }
        const formData = new FormData();
        formData.append("firstname", data.name);
        formData.append("email", data.email);
        formData.append("select_your_role", data.role);
        formData.append("message", data.message);
        await fetch(hubSpotUrl, {
            method: 'post',
            body: formData
        }).then(res => {
            if (res.status === 204) {
                resetForm(actions, recaptcha);
            }
        })
    }

    return (
        <Container className='container-fluid py-3 border-[1px] border-solid border-[#979797] px-5'>
            {/* <Row>
                <Col className='mb-[6%]'>
                    <h6 className="font-serif text-gray-900 text-center font-medium mb-[25px] lg:mb-[15px]">Contact Form</h6>
                </Col>
            </Row> */}
            <div className='my-[6%]'>
                <Formik
                    initialValues={{ name: '', email: '', role: '', message: '', recaptcha: '' }}
                    validationSchema={ContactFormStyle02Schema}
                    onSubmit={async (values, actions) => {
                        actions.setSubmitting(true)
                        if (values.recaptcha !== '') {
                            await submitDataToHubSpot(values, actions)
                        } else {
                            recaptcha.current.captcha.classList.add("error")
                        }


                    }}
                >
                    {({ isSubmitting, status, setFieldValue }) => (
                        <Form ref={form1}>
                            <Input showErrorMsg={false} name="name" type="text" className="py-[18px] px-[25px] w-full border-[1px] border-solid border-[#BDBDBD]" placeholder="Name" labelClass="mb-[25px]" />
                            <Input showErrorMsg={false} name="email" type="email" className="py-[18px] px-[25px] w-full border-[1px] border-solid border-[#BDBDBD]" placeholder="Email Address" labelClass="mb-[25px]" />
                            <Select name="role" className="py-[18px] px-[25px] w-full border-[1px] border-solid border-[#BDBDBD]" labelClass="mb-[25px]">
                                <option value="">Select Role</option>
                                <option value="Utilities">Utilities</option>
                                <option value="Engineering/Construction Company">Engineering/Construction Company</option>
                                <option value="Surveying">Surveying</option>
                                <option value="Investor">Investor</option>
                                <option value="Partner">Partner</option>
                                <option value="Other">Other</option>
                            </Select>
                            {/* <Input showErrorMsg={false} name="mobile" type="tel" className="py-[18px] px-[25px] w-full border-[1px] border-solid border-[#BDBDBD]" placeholder="Mobile number" labelClass="mb-[25px]" /> */}
                            <TextArea
                                className="mb-[35px] pt-[22px] px-[25px] pb-[32px] w-full border-[1px] border-solid border-[#BDBDBD] resize-none"
                                name="message"
                                rows="5"
                                placeholder="Your message"></TextArea>

                            <ReCAPTCHA
                                ref={recaptcha}
                                className="mb-[35px] captcha-custom-width"
                                sitekey={(window.location.origin.includes('localhost') || window.location.origin.includes('10.200.0.78')) ? "6Ldt4nkpAAAAAJNuNgP1Yszx6yGCPzLbPEQ-7Uc-" : "6LfvuXopAAAAAK_MJ5SFZwCUhrfEemhOMMEth8TF"}
                                onChange={(response) => { setFieldValue("recaptcha", response) }}
                            />
                            <Buttons ariaLabel="form button" type="submit" className={`send-message-btn ${isSubmitting ? " loading" : ""}`} size="md" color="#fff" title="Send Message" />
                            <AnimatePresence>
                                {status && <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}><MessageBox className="mt-[35px] py-[10px] tracking-[1px]" theme="message-box01" variant="success" message="Your message has been sent successfully!" /></motion.div>}
                            </AnimatePresence>
                        </Form>
                    )}
                </Formik>
            </div>
        </Container>
    )

}
export default ContactForm;