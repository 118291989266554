import React, { memo } from 'react'

// Libraries
import { Row } from 'react-bootstrap'
import { motion } from "framer-motion"


import TeamStyle04 from './TeamStyle04';
import "../../Assets/scss/components/_team.scss"

import { PropTypes } from 'prop-types';


const TeamData01 = [
    {
        img: 'https://via.placeholder.com/525x639',
        name: 'Alexander Harvard',
        designation: 'CO FOUNDER',
        social_links: [
            {
                title: 'facebook',
                link: "https://www.facebook.com",
                icon: "fab fa-facebook-f"
            },
            {
                title: 'instagram',
                link: "https://www.instagram.com",
                icon: "fa-brands fa-instagram"
            },
            {
                title: 'twitter',
                link: "https://www.twitter.com",
                icon: "fa-brands fa-twitter"
            }
        ]
    },
    {
        img: 'https://via.placeholder.com/525x639',
        name: 'Bryan Jonhson',
        designation: 'MANAGER',
        social_links: [
            {
                title: 'facebook',
                link: "https://www.facebook.com",
                icon: "fab fa-facebook-f"
            },
            {
                title: 'instagram',
                link: "https://www.instagram.com",
                icon: "fa-brands fa-instagram"
            },
            {
                title: 'twitter',
                link: "https://www.twitter.com",
                icon: "fa-brands fa-twitter"
            }
        ]
    },
    {
        img: 'https://via.placeholder.com/525x639',
        name: 'Jemmy Watson',
        designation: 'DESIGNER',
        social_links: [
            {
                title: 'facebook',
                link: "https://www.facebook.com",
                icon: "fab fa-facebook-f"
            },
            {
                title: 'instagram',
                link: "https://www.instagram.com",
                icon: "fa-brands fa-instagram"
            },
            {
                title: 'twitter',
                link: "https://www.twitter.com",
                icon: "fa-brands fa-twitter"
            }
        ]
    },
    {
        img: 'https://via.placeholder.com/525x639',
        name: 'Jeremy Dupont',
        designation: 'MANAGER',
        social_links: [
            {
                title: 'facebook',
                link: "https://www.facebook.com",
                icon: "fab fa-facebook-f"
            },
            {
                title: 'instagram',
                link: "https://www.instagram.com",
                icon: "fa-brands fa-instagram"
            },
            {
                title: 'twitter',
                link: "https://www.twitter.com",
                icon: "fa-brands fa-twitter"
            }
        ]
    }
]

const TeamSwitch = (params, item, i) => {
    return <TeamStyle04 item={item} />
}


const Team = (props) => {
    const style = {
        "--brand-color": typeof (props.overlay) === "object" ? `linear-gradient(to right top, ${props.overlay.map(item => item)})` : props.overlay,
    }

    return (

        <Row className={`${props.grid ? props.grid : ""}${props.className ? ` ${props.className}` : ""}`}>
            {props.data.map((item, i) => {
                return (
                    <motion.div style={style} key={i} className={`col ${props.theme} ${props.themeColor}`} {...{ ...props.animation, transition: { delay: i * props.animationDelay } }} >
                        {TeamSwitch(props, item, i)}
                    </motion.div>
                )
            })}
        </Row>

    )
}

Team.defaultProps = {
    theme: "team-style-01",
    data: TeamData01,
    overlay: ["#556fffcc", "#b263e4cc", "#e05fc4cc", "#f767a6cc", "#ff798ecc"],
    animationDelay: 0.5,
    carouselOption: {
        slidesPerView: 1,
        spaceBetween: 30,
        loop: true,
        navigation: true,
        autoplay: { delay: 3000, disableOnInteraction: false },
        pagination: { dynamicBullets: true, clickable: true },
        breakpoints: { 1200: { slidesPerView: 4 }, 992: { slidesPerView: 3 }, 768: { slidesPerView: 2 } }
    }
}

Team.propTypes = {
    theme: PropTypes.string,
    themeColor: PropTypes.string,
    className: PropTypes.string,
    overlay: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
        PropTypes.string
    ]),
    data: PropTypes.arrayOf(
        PropTypes.exact({
            title: PropTypes.string,
            img: PropTypes.string,
            name: PropTypes.string,
            designation: PropTypes.string,
            subtitle: PropTypes.string,
            social_links: PropTypes.arrayOf(
                PropTypes.exact({
                    title: PropTypes.string,
                    link: PropTypes.string,
                    icon: PropTypes.string,
                    color: PropTypes.string,
                })
            )
        })
    ),
    carouselOption: PropTypes.object,
    animation: PropTypes.object,
    animationDelay: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.number
    ]),
}

export default memo(Team)