import React from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { motion } from 'framer-motion';
import ProcessStep from './ProcessStep.js';
import { fadeIn, fadeInLeft } from '../../Common/GlobalAnimations.js';

const ProcessStepData = [
    {
        title: "Capture",
        content: "Easily collect assets of any size or complexity up to 100x faster. No need to re-field — everything you need in a single visit.",
    },
    {
        title: "Analyze",
        content: "View, interact, and collaborate on survey-grade 3D models from within our data portal.",
    },
    {
        title: "Deliver",
        content: "Build more confidently with data driven outcomes - improving project outcomes and reducing risk.",
    }
]

export const IndustryServe = () => {
    return (
        <>
            <section className="pt-[100px] text-left overflow-hidden md:pt-[85px] sm:pt-[60px] End-to-End-Sec">
                <Container className='mb-5'>
                    <Row>
                        <Col lg={12} className="xs:text-center">
                            <motion.h2 className="heading-5 Banner-servetitle mb-2" {...fadeIn}>
                                End-to-End Solution to Digitizing the Built World
                            </motion.h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} className='Who-we-are'>
                            The information that you need, when you need it, at a scale that matters.
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="pb-[90px] lg:pb-[90px] md:pb-[75px] sm:pb-[50px] overflow-hidden startup-processstep industry-serve-sec">
                <Container className='mt-5'>
                    <Row className="items-center justify-center">
                        <Col xl={5} lg={6} md={10}>
                            {/* <span className="font-size-40 font-Roboto-Condensed color-E0604C font-bold inline-block mb-[20px] sm:mb-[10px]">INDUSTRIES SERVED</span> */}
                            <h2 className="color-E0604C font-size-36 font-Roboto-Condensed font-bold line-height block -tracking-[.5px] mb-20 w-[85%] lg:mb-[80px] md:mb-12 md:w-[80%] sm:w-full">A Seamless Process</h2>
                            <ProcessStep grid="row-cols-1" theme='process-step-style-02' data={ProcessStepData} animation={fadeIn} />
                        </Col>
                        <motion.div className="offset-xl-1 col-lg-6 md:mt-[50px]" {...fadeInLeft}>
                            <div className="-mr-[30vw] relative md:mr-0" >
                                <img src="/assets/img/looq-laptop.png" width={1109} height={638} className="overflow-hidden" alt="laptop" />
                            </div>
                        </motion.div>
                    </Row>
                </Container>
            </section>
        </>
    )
}