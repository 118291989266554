import { useEffect } from "react";



function DemoRegisterRedirect() {

    const redirectToDemoRegister = () => {
        const url = window.location.origin;
        if (url.includes('localhost')) {
            window.location.href = 'http://localhost:3000/demoregister'
        }
        else if (url.includes('dev.looq.ai')) {
            window.location.href = 'https://app-dev.looq.ai/demoregister'
        }
        else if (url.includes('looq.ai')) {
            window.location.href = 'https://app.looq.ai/demoregister'
        }
    }

    useEffect(() => {
        redirectToDemoRegister();
    }, []);

    return (<></>);

}

export default DemoRegisterRedirect;