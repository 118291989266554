import React from 'react';
import { useEffect } from 'react';
import { Col, Navbar } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Header, HeaderNav } from './Header';
import { HeaderText } from './HeaderText';
import { IndustryServe } from './IndustryServe';
import InViewPort from './InViewPort';
import { Benefits } from './Benefits';
import { Footer } from './Footer';
import { CustomerReview } from './CustomerReview';
import { PopularArticles } from './PopularArticles';
import Subscribe from './Subscribe';



// Component
// import SocialIcons from '../../Components/SocialIcon/SocialIcons';
import Product from './Product';
import Technology from './Technology';
import { Menu } from './Header';
import IndustryUtilities from './IndustryUtilities';

const LandingPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        window.dataLayer.push({
            event: 'Home'
        });
    }, []);
    return (
        <div>
            <Header topSpace={{ md: true }} type="reverse-scroll">
                <HeaderNav theme="dark" expand="lg" className="px-[35px] py-[0px]  md:pl-0 md:pr-[15px] md:py-[20px] sm:pr-0">
                    <Col className="col-auto col-sm-6 col-lg-2 mr-auto ps-lg-0 sm:!pl-0">
                        <Link aria-label="header logo" className="flex items-center" to="/">
                            <Navbar.Brand className="inline-block p-0 m-0">
                                <img className="default-logo" width="111" height="36" loading="lazy" src='/assets/img/looq-logo-white.png' data-rjs='/assets/img/looq-logo.png' alt='logo' />
                                <img className="alt-logo" width="111" height="36" loading="lazy" src='/assets/img/looq-logo.png' data-rjs='/assets/img/looq-logo.png' alt='logo' />
                                <img className="mobile-logo" width="111" height="36" loading="lazy" src='/assets/img/looq-logo.png' data-rjs='/assets/img/looq-logo.png' alt='logo' />
                            </Navbar.Brand>
                        </Link>
                    </Col>
                    <Navbar.Toggle className="order-last md:ml-[25px] sm:ml-[17px]">
                        <span className="navbar-toggler-line"></span>
                        <span className="navbar-toggler-line"></span>
                        <span className="navbar-toggler-line"></span>
                        <span className="navbar-toggler-line"></span>
                    </Navbar.Toggle>
                    <Navbar.Collapse className="col-auto justify-end pr-[15px]">
                        <Menu />
                    </Navbar.Collapse>
                    {/* <SearchBar /> */}
                    {/* <div className="md:hidden pl-[17px]">
                        <HamburgerMenu className="" theme="dark">
                            <Col className="flex flex-col justify-center px-[50px] py-[70px] w-[500px] h-[100vh] shadow-[0_0_20px_rgba(0,0,0,0.3)] right-0 text-center bg-white">
                                <ReactCustomScrollbar theme="dark">
                                    <div>
                                        <h1 className="mb-0 font-bold tracking-[-3px] text-darkgray tracking-[-3px] font-serif uppercase">Hello</h1>
                                        <p className="text-lg font-serif uppercase block">Let's be friends.</p>
                                        <div className="my-20 w-full">
                                             <StaticInstagram />
                                        </div>
                                        <div className="mb-20 text-center">
                                            <p className="w-[70%] mb-12 text-darkgray leading-[26px] text-lg font-serif mx-auto inline-block">Get latest update for our trusted applications</p>
                                            <Formik
                                                initialValues={{ email: '' }}
                                                validationSchema={Yup.object().shape({ email: Yup.string().email("Invalid email.").required("Field is required."), })}
                                                onSubmit={async (values, actions) => {
                                                    actions.setSubmitting(true)
                                                    const response = await sendEmail(values)
                                                    response.status === "success" && resetForm(actions)
                                                }}
                                            >
                                                {({ isSubmitting, status }) => (
                                                    <div className="relative subscribe-style-05">
                                                        <Form className="relative">
                                                            <Input showErrorMsg={false} type="email" name="email" className="border-[1px] medium-input rounded-[5px] border-solid border-[#dfdfdf]" placeholder="Enter your email address" />
                                                            <button aria-label="Subscribe" type="submit" className={`text-xs leading-[18px] py-[12px] px-[28px] tracking-normal uppercase xs:text-center${isSubmitting ? " loading" : ""}`}><i className="far fa-envelope text-darkgray text-sm leading-none mr-[10px] xs:mr-0"></i>Subscribe</button>
                                                        </Form>
                                                        <AnimatePresence>
                                                            {status && <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className="mt-[25px] top-[115%] left-0 w-full"><MessageBox className="rounded-[4px] text-md py-[10px] px-[22px] z-10" theme="message-box01" variant="success" message="Your message has been sent successfully subscribed to our email list!" /></motion.div>}
                                                        </AnimatePresence>
                                                    </div>
                                                )}
                                            </Formik>
                                        </div>
                                        <div className="mb-[10px] text-center">
                                             <SocialIcons theme="social-icon-style-05" size="sm" iconColor="dark" data={SocialIconsData} /> 
                                        </div>
                                    </div>
                                </ReactCustomScrollbar>
                            </Col>
                        </HamburgerMenu>
                    </div> */}
                </HeaderNav>
            </Header>
            <HeaderText showVideo={true}></HeaderText>
            <IndustryServe></IndustryServe>
            <InViewPort>
                <IndustryUtilities></IndustryUtilities>
                <Technology></Technology>
                <Product></Product>
                <Benefits></Benefits>
                <CustomerReview></CustomerReview>
                <PopularArticles></PopularArticles>
                <Subscribe></Subscribe>
                <Footer></Footer>
            </InViewPort>

        </div>
    )
}

export default LandingPage;