import React from 'react';

// Libraries
import { Col, Container, Row } from 'react-bootstrap';

// Components
import { fadeIn } from '../../Common/GlobalAnimations';
import { motion } from 'framer-motion';


const IndustryUtilities = () => {
    return (
        <section className="p-[0px] sm:pt-[50px]">
            <Container fluid>
                <Row className="justify-center">
                    <div class="mb-3 text-center col-lg-12">
                        <h2 class="font-size-46 font-Roboto-Condensed font-bold color-E0604C">Industries Served</h2></div>
                </Row>
                <Row className="justify-center">

                    <Col lg={{ span: 4, order: 0 }} md={{ span: 6, order: 1 }} sm={8} className="p-[0px]">
                        <motion.div className="relative h-full flex items-center bg-cover bg-no-repeat overflow-hidden bg-center text-center" style={{ backgroundImage: 'url("/assets/img/Rectangle_752.png")' }}  {...{ ...fadeIn, transition: { delay: 0.2 } }}>
                            <div className="absolute h-full w-full top-0 left-0 bg-darkgray opacity-60"></div>
                            <div className="relative z-[1] w-full py-40 lg:py-28">
                                <h2 className="text-white w-[75%] lg:w-[85%] mx-auto mb-[35px] font-medium font-Roboto-Condensed font-size-40">Utilities</h2>
                                <span className="block text-white font-Roboto-Condensed font-size-18 tracking-[2px] mb-[25px] w-[75%] lg:w-[85%] mx-auto">
                                    Survey-grade digital twins to maintain, analyze, and manage infrastructure assets
                                </span>
                            </div>
                        </motion.div>
                    </Col>
                    <Col lg={{ span: 4, order: 0 }} md={{ span: 6, order: 3 }} sm={8} className="p-[0px]">
                        <motion.div className="relative h-full flex items-center bg-cover bg-no-repeat overflow-hidden bg-center text-center" style={{ backgroundImage: 'url("/assets/img/Rectangle_751.png")' }}  {...{ ...fadeIn, transition: { delay: 0.2 } }}>
                            <div className="absolute h-full w-full top-0 left-0 bg-darkgray opacity-60"></div>
                            <div className="relative z-[1] w-full py-40 lg:py-28">
                                <h2 className="text-white w-[75%] lg:w-[85%] mx-auto mb-[35px] font-medium font-Roboto-Condensed font-size-40">EPC</h2>
                                <span className="block text-white font-Roboto-Condensed font-size-18 tracking-[2px] mb-[25px] w-[75%] lg:w-[85%] mx-auto">
                                    Timely, accurate, affordable data at infrastructure scale.
                                </span>
                            </div>
                        </motion.div>
                    </Col>
                    <Col lg={{ span: 4, order: 0 }} md={{ span: 6, order: 2 }} sm={8} className="p-[0px]">
                        <motion.div className="relative h-full flex items-center bg-cover bg-no-repeat overflow-hidden bg-center text-center" style={{ backgroundImage: 'url("/assets/img/Rectangle_753.png")' }}  {...{ ...fadeIn, transition: { delay: 0.6 } }}>
                            <div className="absolute h-full w-full top-0 left-0 bg-darkgray opacity-60"></div>
                            <div className="relative z-[1] w-full py-40 lg:py-28">
                                <h2 className="text-white w-[75%] lg:w-[85%] mx-auto mb-[35px] font-medium font-Roboto-Condensed font-size-40">Surveyors</h2>
                                <span className="block text-white font-Roboto-Condensed font-size-18 tracking-[2px] mb-[25px] w-[75%] lg:w-[85%] mx-auto">
                                    Comprehensive surveys with safety, speed, and accuracy.
                                </span>
                            </div>
                        </motion.div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default IndustryUtilities;