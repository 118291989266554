import React, { useState, useEffect, useRef, memo, useLayoutEffect } from "react";

// Libraries
import { Link } from "react-router-dom";
import { PropTypes } from "prop-types";
import { motion } from "framer-motion";


const BlogGrid = (props) => {
    const blogWrapper = useRef();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        import("../Functions/Utilities").then(module => {
            const grid = module.initializeIsotop(blogWrapper.current)
            grid.on('arrangeComplete', () => setLoading(false));
        })
    }, [])
    useLayoutEffect(() => {
        function updateSize() {
            var elements = document.getElementsByClassName('blog-auto-height');
            var maxHeight = 0;
            for (var i = 0; i < elements.length; i++) {
                var eleHeight = elements[i].getBoundingClientRect().height;
                maxHeight = Math.max(maxHeight, eleHeight);
            }
            for (var j = 0; j < elements.length; j++) {
                elements[j].style.height = maxHeight + 'px';
            }

        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);

    }, [])

    const style = {
        "--overlay-color": typeof (props.overlay) === "object" ? `linear-gradient(to right top, ${props.overlay.map((item) => item)})` : props.overlay
    }


    return (
        <div className="grid-wrapper">
            {/* <Filter title={props.title} filterData={props.filterData} onFilterChange={handleFilterChange} /> */}
            <ul ref={blogWrapper} className={`grid-container${props.grid ? ` ${props.grid}` : ""}${loading ? " loading" : ""}${props.filter === false ? "" : " mt-2 md:mt-[4.5rem] sm:mt-5"}`}>
                <li className="grid-sizer"></li>
                {props.data.map((item, i) => {
                    return (
                        <li className={`blog-auto-height grid-item${item.double_col ? " grid-item-double" : ""} ${item.category.map(item => item.split(" ").join("")).toString().split(",").join(" ").toLowerCase()}`} key={i}
                        >
                            <motion.div className="rounded-[5px] overflow-hidden border h-full"
                                initial={{ opacity: 0 }}
                                whileInView={!loading && { opacity: 1 }}
                                viewport={{ once: true }}
                                transition={{ duration: 0.9, ease: "easeOut" }}
                            >
                                <div className="blog-post relative overflow-hidden" style={style}>
                                    {item.img && (
                                        <Link aria-label="link" to={`${item.link}`} target="_blank">
                                            <img height={245} src={item.img} alt="blog" />
                                        </Link>
                                    )}
                                    {/* {item.img && (
                                        <Link aria-label="link"
                                            to={`/blogs/category/${[item.category[0].toString().split(" ").join("").toLowerCase()]}`}
                                            className="blog-grid-catagory bg-[#171717bf] font-serif text-white text-xxs uppercase px-[13px] py-[6px] rounded-[2px] absolute top-[23px] right-[23px]">
                                            {item.category[0]}
                                        </Link>
                                    )} */}
                                </div>
                                <div className="px-4 py-4 sm:px-4 xs:px-4">
                                    {item.date && (<span className="mb-[10px] text-sm font-serif inline-block" > {item.date} </span>)}
                                    {item.title && (<Link aria-label="link" to={`${item.link}`} target="_blank" className="font-Roboto-Condensed mb-[15px] text-xmd block font-bold color-000000 hover:text-basecolor" > {item.title} </Link>)}
                                    {item.content && (<p className="color-515151 font-Roboto-Condensed"> {item.content} </p>)}
                                </div>
                            </motion.div>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

BlogGrid.propTypes = {
    pagination: PropTypes.bool,
    title: PropTypes.string,
    grid: PropTypes.string,
    link: PropTypes.string,
    data: PropTypes.arrayOf(
        PropTypes.exact({
            id: PropTypes.number,
            category: PropTypes.array,
            tags: PropTypes.array,
            blogType: PropTypes.string,
            img: PropTypes.string,
            title: PropTypes.string,
            content: PropTypes.string,
            author: PropTypes.number,
            likes: PropTypes.number,
            comments: PropTypes.number,
            date: PropTypes.string,
            double_col: PropTypes.bool
        })
    ),
};

export default memo(BlogGrid);