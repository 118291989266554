import React, { useEffect, useState, useContext, memo, useRef } from "react";
import PropTypes from "prop-types";
import { useScroll } from "framer-motion";
import { useLocation, Link } from "react-router-dom";
import { Container, Navbar } from "react-bootstrap";
//import "../../Assets/scss/layouts/_header.scss";
import GlobalContext from "../../Common/GlobalContext";
import NavMenuOptions from './Navmenu';
import useOnClickOutside from "../Functions/UseOnClickOutside";

import "../../Assets/scss/layouts/_header.scss";


/* Header Component Start */
export const Header = memo((props) => {
    // Add Global Header Data
    const { setHeaderHeight } = useContext(GlobalContext);
    const { scrollY } = useScroll();
    const [scrollPos, setScrollPos] = useState({
        y: 0,
        prevY: -1,
        directionDown: true,
    });
    const location = useLocation()

    useEffect(() => {
        let headerEl = document.querySelector("header");

        // Calculate header height
        function setTopSpace() {
            let windowWidth = window.innerWidth,
                headerheight = (props.topSpace.desktop && props.topSpace.desktop === true) ? headerEl.offsetHeight : 0;

            if (windowWidth <= 1199 && props.topSpace.lg) {
                headerheight = props.topSpace.lg === true ? headerEl.offsetHeight : 0;
            }

            if (windowWidth <= 991 && props.topSpace.md) {
                headerheight = props.topSpace.md === true ? headerEl.offsetHeight : 0;
            }

            if (windowWidth <= 767 && props.topSpace.sm) {
                headerheight = props.topSpace.sm === true ? headerEl.offsetHeight : 0;
            }

            if (windowWidth <= 575 && props.topSpace.xs) {
                headerheight = props.topSpace.xs === true ? headerEl.offsetHeight : 0;
            }

            setHeaderHeight(headerheight);
        }

        setTopSpace();

        window.addEventListener("load", setTopSpace);
        window.addEventListener("resize", setTopSpace);

        if (document.body.classList.contains("mobile-menu")) {
            document.body.classList.remove("navbar-collapse-show")
            document.body.classList.remove("menu-modern")
            document.body.classList.remove("menu-full")
            document.body.style.removeProperty("overflow");
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    useEffect(() => {
        let lastScrollTop = 0;
        scrollY.onChange((pos) => {
            if (pos > lastScrollTop) {
                setScrollPos({
                    ...scrollPos,
                    y: pos,
                    prevY: pos - 1,
                    directionDown: true,
                });
            } else {
                setScrollPos({
                    ...scrollPos,
                    y: pos,
                    prevY: pos - 1,
                    directionDown: false,
                });
            }

            if (pos === 0) {
                setScrollPos({ ...scrollPos, directionDown: true });
            }
            lastScrollTop = pos;
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <header
            className={`${props.className ? props.className : ""}${scrollPos.y > 5 ? " sticky-header" : ""}${scrollPos.directionDown === false ? " header-appear" : ""}${props.type ? ` ${props.type}` : ""
                } looq-navigation`}
        >
            {props.children}
        </header>
    );
});
/* Header Component End */

/* Headernav Component Start */
export const HeaderNav = (props) => {
    const handleMenuToggle = () => {
        let header = document.querySelector("header"),
            menu = header.querySelector(".navbar-nav"),
            menu_item = menu.querySelectorAll(".nav-item");

        if (!document.body.classList.contains("navbar-collapse-show")) {
            document.body.classList.add("navbar-collapse-show");
        } else {
            document.body.classList.remove("navbar-collapse-show");
        }

        menu_item.forEach(function (item) {
            if (item.classList.contains("open")) {
                setTimeout(() => {
                    item.classList.remove("open");
                }, 200);
            }
        });
    };

    return (
        <Navbar
            collapseOnSelect
            id="headerbar"
            expand={props.expand}
            bg={props.bg ? props.bg : "transparent"}
            variant={props.theme}
            className={`${props.menu && `menu-${props.menu}`}${props.className ? ` ${props.className}` : ""
                }${props.bg || props.bg === "transparent" ? "" : " header-transparent"}`}
            onToggle={handleMenuToggle}
        >
            <Container
                fluid={props.fluid}
                className={props.containerClass ? props.containerClass : ""}
            >
                {props.children}
            </Container>
        </Navbar>
    );
};

export const Menu = memo((props) => {
    const [isMenuActive, setMenuActive] = useState(null);
    const [isHover, setIsHover] = useState(false)
    const handleMenuClick = (e, index) => setMenuActive(index !== isMenuActive ? index : null);

    // set Active Menu
    const location = useLocation()

    useEffect(() => {
        let header = document.querySelector("header"),
            links = header.querySelectorAll(".nav-link");

        const activeLink = Array.from(links).find(link => link.getAttribute("href") === location.pathname)
        if (activeLink) {
            import("../Functions/Utilities").then(module => {
                let filtered_dropdown = module.getParents(activeLink).filter(item => item.classList.contains('simple-dropdown'))
                let filtered_nav_item = module.getParents(activeLink).filter(item => item.classList.contains('nav-item'))
                filtered_dropdown.forEach(item => item.classList.add("active"))
                filtered_nav_item.forEach(item => item.classList.add("active"))
            })
        }
    }, [location, isHover])

    useEffect(() => {
        let navItems = document.querySelector("header").querySelectorAll(".navbar-nav > .nav-item")
        navItems.forEach(nav => nav.addEventListener("mouseover", () => setIsHover(true)))
    }, [])

    return (
        <div className={`${props.mobileMenu ? `mobile-menu-${props.mobileMenu}` : ""}${props.className ? ` ${props.className}` : ""}`}>
            <ul className="navbar-nav">
                {props.data.map((item, i) => {
                    return (
                        <li className={`nav-item`} key={i}>
                            {
                                item.link ? (
                                    item.target ?
                                        <Link className="nav-link" to={item.link} target={`${item.target}`}>
                                            {item.title}
                                        </Link> :

                                        <Link className="nav-link" to={item.link}>
                                            {item.title}
                                        </Link>
                                ) : (
                                    <span className="nav-link">{item.title}</span>
                                )
                            }
                            <i className="fa fa-angle-down" onClick={(e) => handleMenuClick(e, i)} />
                            {/* {(item.dropdown) && (
                                <ul className="simple-dropdown-menu">
                                    {item.dropdown.map((item, i) => {
                                        return (
                                            <li key={i} className="simple-dropdown">
                                                {
                                                    item.link ? (
                                                        <Link className="nav-link" to={item.link}>
                                                            {item.title}
                                                            {item.dropdown && (<i className="fas fa-angle-right"></i>)}
                                                        </Link>
                                                    ) : (
                                                        <span className="nav-link">
                                                            {item.title}
                                                            {item.dropdown && (<i className="fas fa-angle-right"></i>)}
                                                        </span>
                                                    )
                                                }
                                                {item.dropdown && (
                                                    <ul className="simple-dropdown-menu">
                                                        {item.dropdown.map((item, i) => {
                                                            return (
                                                                <li key={i} className="simple-dropdown">
                                                                    {
                                                                        item.link ? (
                                                                            <Link
                                                                                className={`nav-link${item.dropdown ? " md:text-black md:mt-[15px] md:mb-[7px]" : ""}`}
                                                                                to={item.link}
                                                                            >
                                                                                {item.title}
                                                                                {item.dropdown && (<i className="fas fa-angle-right"></i>)}
                                                                            </Link>
                                                                        ) : (
                                                                            <span className="nav-link">
                                                                                {item.title}
                                                                                {item.dropdown && (<i className="fas fa-angle-right"></i>)}
                                                                            </span>
                                                                        )
                                                                    }
                                                                    {item.dropdown && (
                                                                        <ul className="simple-dropdown-menu">
                                                                            {item.dropdown.map((item, i) => {
                                                                                return (
                                                                                    <li
                                                                                        className="simple-dropdown"
                                                                                        key={i}
                                                                                    >
                                                                                        <Link className="nav-link" to={item.link}>{item.title}</Link>
                                                                                    </li>
                                                                                );
                                                                            })}
                                                                        </ul>
                                                                    )}
                                                                </li>
                                                            );
                                                        })}
                                                    </ul>
                                                )}
                                            </li>
                                        );
                                    })}
                                </ul>
                            )} */}
                            {/* {(item.megamenu) && (
                                <div className="flex megamenu" ref={megamenu_ref}>
                                    {item.megamenu.map((item, i) => {
                                        return (
                                            <ul className={`${(item.dropdown.filter(item => item.img).length > 0) ? "!pr-[30px] img-wrapper inline-block last:!pr-[0px]" : "inline-block"}`} key={i}>
                                                {item.title && <li className="title text-md font-medium mb-[15px] whitespace-nowrap">
                                                    {item.title}
                                                </li>}
                                                {item.dropdown &&
                                                    item.dropdown.map((item, i) => {
                                                        return (
                                                            <li className="nav-item" key={i}>
                                                                {item.title && <Link className="nav-link" to={item.link ? item.link : "#"} > {item.icon && (<i className={`${item.icon} mr-[10px]`} ></i>)}{" "}
                                                                    {item.title}
                                                                </Link>}
                                                                {(item.img && item.link) && <Link to={item.link}><img height="235" alt="menu-banner" width="210" className="inline-block max-w-[210px]" src={item.img} /></Link>}
                                                            </li>
                                                        );
                                                    })}
                                            </ul>
                                        );
                                    })}
                                </div>
                            )} */}
                        </li>
                    );
                })}
            </ul>
        </div>
    );
});

export const HamburgerMenu = memo((props) => {
    const ref = useRef(null);
    const [show, setShow] = useState(false);
    useOnClickOutside(ref, () => setShow(false));

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                setShow(false);
            }
        };

        if (show === true) {
            document.querySelector("body").classList.add("overflow-hidden");
            document.querySelector(".push-button").classList.remove("collapsed");
        } else {
            document.querySelector("body").classList.remove("overflow-hidden");
            document.querySelector(".push-button").classList.add("collapsed");
        }

        window.addEventListener("keydown", close);
        return () => window.removeEventListener("keydown", close);
    }, [show]);

    return (
        <>
            {[false].map((expand) => (
                <Navbar
                    key={expand}
                    expand={expand}
                    className={`header-push-button bg-transparent inline-block ${props.theme}`}
                >
                    <Navbar.Toggle className={`push-button`} onClick={() => setShow(true)}>
                        {/* <div className="nav-icon">
                            <span className="navbar-toggler-line"></span>
                            <span className="navbar-toggler-line"></span>
                            <span className="navbar-toggler-line"></span>
                            <span className="navbar-toggler-line"></span>
                        </div> */}
                    </Navbar.Toggle>
                    <div className={`${show ? "block h-[100vh] left-0 overflow-hidden fixed top-0 w-full z-[999]" : ""}`}>
                        <div
                            ref={ref} className={`hamburger-menu-wrapper pos-${props.position}${show ? " show" : ""
                                }${props.className ? ` ${props.className}` : ""}`}
                        >
                            {props.closeBtn && (
                                <button aria-label="hamburger menu close button" className="close-btn" onClick={() => setShow(false)}>
                                    <i className="fas fa-times"></i>
                                </button>
                            )}
                            {show && props.children}
                        </div>
                    </div>
                </Navbar>
            ))}
        </>
    );
});

HamburgerMenu.defaultProps = {
    theme: "light",
    position: "right",
    closeBtn: true,
};

HamburgerMenu.propTypes = {
    theme: PropTypes.string,
    position: PropTypes.string,
    closeBtn: PropTypes.bool,
};

Header.defaultProps = {
    topSpace: {
        desktop: false,
    },
};

Header.propTypes = {
    type: PropTypes.string,
    topSpace: PropTypes.object,
};

HeaderNav.defaultProps = {
    fluid: "lg",
    theme: "dark",
    menu: "light",
    expand: "lg",
};

HeaderNav.propTypes = {
    fluid: PropTypes.string,
    theme: PropTypes.string,
    bg: PropTypes.string,
    className: PropTypes.string,
};
Menu.defaultProps = {
    data: NavMenuOptions,
};